<script>
import { Cascader } from 'ant-design-vue'

export default {
  methods: {
    getContainerEl() {
      console.log('getContainerEl', document.querySelector('.container'))
      return document.querySelector('.container')
    },
    renderContainer() {
      return (
        <div class="container ">
          <div class="div1"></div>
          {this.renderCascader()}
        </div>
      )
    },

    renderCascader() {
      return <Cascader getPopupContainer={this.getContainerEl} />
    }
  },
  render() {
    return <div>{this.renderContainer()}</div>
  }
}
</script>

<style lang="less" scoped>
.container {
  background: red;
  height: 500px;
  overflow-y: scroll;
  position: relative;
}

.div1 {
  height: 1000px;
}
</style>
